
  import { Component, Vue, Prop } from "vue-property-decorator";
  import LzModal from "@/components/Modal.vue";
  import LzButton from "@/components/Button.vue";
  import { apiOngs } from "../../api";

  type TTemplate = Record<"id" | "image" | "name", string>;

  @Component({
    components: { LzButton, LzModal }
  })
  export default class ChooseTemplate extends Vue {
    showModal = false;
    templates: TTemplate[] = [];
    modalImage = "";

    @Prop({ required: true })
    protected readonly chosenTemplateId!: string;

    @Prop({ required: true })
    public handleChooseTemplate!: (e: Event) => void;

    async mounted() {
      const { data } = await apiOngs.getTemplates();
      this.templates = [
        {
          id: "e1849ec4-d72e-4c4c-94d0-c3395f39e7da",
          name: "template-1",
          image:
            "https://uncompressed-images.s3.eu-west-2.amazonaws.com/template1_ong.jpeg"
        },
        {
          id: "baae4248-99e8-4ad9-9874-0fade99ef4e9",
          name: "template-2",
          image:
            "https://uncompressed-images.s3.eu-west-2.amazonaws.com/template2_ong.png"
        },
        {
          id: "d597f2ad-cd6b-4deb-84b0-19a59328ccb0",
          name: "template-3",
          image:
            "https://uncompressed-images.s3.eu-west-2.amazonaws.com/template3_ong.png"
        }
      ];
    }

    openModal(clickedTemplateId = "") {
      this.showModal = true;

      this.modalImage =
        this.templates.find(({ id }) => id === clickedTemplateId)?.image || "";
    }

    closeModal() {
      this.showModal = false;
    }

    get theme() {
      return (templateId: TTemplate["id"]) =>
        templateId === this.chosenTemplateId
          ? this.chosenTemplateTheme
          : this.fallBackTheme;
    }

    get chosenTemplateTheme() {
      return {
        "--template-footer-background-color": "#eb2873",
        "--svg-icon-color": "white",
        "--template-text-color": "white"
      };
    }

    get fallBackTheme() {
      return {
        "--template-footer-background-color": "#ffffff",
        "--svg-icon-color": "#2c3e50",
        "--template-text-color": "black"
      };
    }
    get computedTemplates() {
      return this.templates?.map((template, i) => ({
        id: template.id,
        style:
          template.id === this.chosenTemplateId
            ? this.chosenTemplateTheme
            : this.fallBackTheme,
        backgroundImage: { backgroundImage: `url(${template.image})` },
        text: this.$t(`web.public.chooseTemplate.template${i + 1}`)
      }));
    }
  }
